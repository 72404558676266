import { FC, useState } from "react"
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Container,
  Typography,
} from "@mui/material"
import { CreateOrderForm, OrdersTable } from "../components"
import { OrderFilters } from "../components"
import { useTitle } from "@/core/hooks"
import { CreateCampaignForm } from "../components/forms/createCampaignForm"
import { Close } from "@mui/icons-material"

interface IProps {}
enum TabId {
  CreateCampaign = 1,
  CreateOrder,
}

export const OrdersPage: FC<IProps> = (props) => {
  const [expanded, setExpanded] = useState<TabId | undefined>()
  useTitle(`PR ADV - Заказы`)

  return (
    <Container maxWidth={false}>
      <Box mb={3}>
        <Box mb={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonGroup>
            <Button onClick={() => setExpanded(TabId.CreateCampaign)}>
              Создать новую кампанию
            </Button>
            <Button onClick={() => setExpanded(TabId.CreateOrder)}>
              Создать новый заказ
            </Button>
          </ButtonGroup>
          {!!expanded && (
            <Box ml={1} display="inline">
              <Button onClick={() => setExpanded(undefined)}>
                <Close />
              </Button>
            </Box>
          )}
        </Box>
        <Collapse in={!!expanded}>
          {expanded === TabId.CreateCampaign && (
            <>
              <Typography variant="h5">Создание новой кампании</Typography>
              <CreateCampaignForm />
            </>
          )}
          {expanded === TabId.CreateOrder && (
            <>
              <Typography variant="h5">Создание нового заказа</Typography>
              <CreateOrderForm />
            </>
          )}
        </Collapse>
      </Box>
      <Box>
        <Typography variant="h5">Заказы</Typography>
        <OrderFilters />
        <Box height="85vh">
          <OrdersTable />
        </Box>
      </Box>
    </Container>
  )
}
