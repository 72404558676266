import { FC } from "react"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useCreateAgentMutation } from "../../store"
import { Grid, Stack } from "@mui/material"
import { Name } from "../formFields/name"
import { Type } from "../formFields/typeSelect"
import { Url } from "../formFields/url"
import { Location } from "../formFields/locationsSelect"
import { AgentContacts } from "../formFields/agentContacts"
import { EMPTY_CONTACT } from "../../constants/adv"

interface IProps {}
interface IFormData extends ADV.CreateAgent {}

export const CreateAgentForm: FC<IProps> = (props) => {
  return (
    <RtkHookForm<IFormData, ADV.CreateAgentRes, typeof useCreateAgentMutation>
      successMessage="Агент создан успешно"
      mutationHook={useCreateAgentMutation}
      formContextParams={{
        defaultValues: {
          contacts: [EMPTY_CONTACT]
        }
      }}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Name<IFormData> required />
            <Type<IFormData> required name={"type_id"} for="agents" />
            <Url<IFormData> name={"url"} />
            <Location<IFormData> name={"geo"} multiple />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AgentContacts<IFormData> minLength={1} />
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
