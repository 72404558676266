import { FC } from "react"
import { DataGrid } from "@/core/components"
import {
  GridActionsCellItem,
  gridClasses,
  GridColDef,
} from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks"
import { Alert, alpha, Link, Theme, Typography } from "@mui/material"
import { LinkOff } from "@mui/icons-material"
import {
  getTgstatBotState,
  setInviteLinksPaginationModel,
  useGetInviteLinksQuery,
  useRevokeInviteLinkMutation,
} from "../store"
import { TgstatBot } from "../interfaces"
import { useParams } from "react-router-dom"
import { formatDateAndTime } from "@/core/utils"

const styles = (theme: Theme) => ({
  [`&.MuiDataGrid-root .${gridClasses.row}--revoked`]: {
    backgroundColor: `${alpha(theme.palette.grey[700], 0.1)} !important`,
  },
})

interface IProps {
  canEdit: boolean
}

export const InviteLinksTable: FC<IProps> = ({canEdit}) => {
  const { chatId, botId } = useParams()
  const dispatch = useAppDispatch()
  const {
    inviteLinks: { page, pageSize },
  } = useAppSelector(getTgstatBotState)
  const { isFetching, data: dataAndTotal } = useGetInviteLinksQuery({
    queryParams: {
      chat_id: chatId!,
      bot_id: botId!,
      page: page + 1,
      page_size: pageSize,
    },
  })
  const [data, totalCount] = dataAndTotal ?? []
  const [revoke, { isLoading: isRevokeLoading }] = useRevokeInviteLinkMutation()

  const columns: GridColDef<TgstatBot.InviteLink>[] = [
    {
      headerName: "Дата создания",
      field: "created_at",
      flex: 1,
      valueGetter: ({ value }) => formatDateAndTime(value),
    },
    {
      headerName: "Название",
      field: "name",
      flex: 2,
    },
    {
      headerName: "Комментарий",
      field: "comment",
      flex: 3,
    },
    {
      headerName: "Присоединилось всего",
      field: "joined_count",
      flex: 1,
    },
    {
      headerName: "Отписалось всего",
      field: "left_count",
      flex: 1,
    },
    {
      headerName: "Ссылка",
      field: "invite_link",
      renderCell: ({ value }) => (
        <Link
          color="secondary"
          href={value}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {value}
        </Link>
      ),
      flex: 3,
    },
    {
      headerName: "Канал",
      field: "chat",
      valueGetter: ({ value }) => value.chatTitle,
      flex: 3,
    },
    {
      headerName: "Лимит вступлений",
      field: "member_limit",
      flex: 1,
    },
    {
      headerName: "Действительна до",
      field: "expire_date",
      flex: 1,
      valueGetter: ({ value }) => formatDateAndTime(value),
    },
    {
      headerName: "По заявкам",
      field: "creates_join_request",
      flex: 1,
      type: "boolean",
    },
    {
      headerName: "Отозвана",
      field: "is_revoked",
      flex: 1,
      type: "boolean",
    },
  ]

  return (
    <DataGrid<TgstatBot.InviteLink>
      columns={columns}
      rows={data ?? []}
      getRowId={(row) => row.invite_link}
      rowCount={totalCount ?? 0}
      loading={isFetching || isRevokeLoading}
      pagination
      paginationMode="server"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      onPaginationModelChange={(model) =>
        dispatch(setInviteLinksPaginationModel(model))
      }
      sx={styles}
      initialState={{
        pagination: { paginationModel: { page, pageSize } },
        columns: {
          columnVisibilityModel: {
            chat: chatId === undefined,
          },
        },
      }}
      getRowClassName={(params) =>
        params.row.is_revoked ? `${gridClasses.row}--revoked` : ""
      }
      rowActions={[
        (r) => (
          <GridActionsCellItem
            label="Отозвать"
            icon={<LinkOff />}
            showInMenu
            disabled={r.row.is_revoked || !canEdit }
            onClick={() => revoke({ body: { invite_link: r.row.invite_link } })}
          />
        ),
      ]}
    />
  )
}
