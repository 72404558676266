import { FC, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  Typography,
} from "@mui/material"
import { useTitle } from "@/core/hooks"
import { InviteLinksTable } from "../components/inviteLinksTable"
import { CreateInviteLinkForm } from "../components/createInviteLinkForm"
import { GoBack } from "@/core/components"
import { useGetActiveBotQuery, useGetChatQuery } from "../store"
import { useParams } from "react-router-dom"

interface IProps {}

export const ChatPage: FC<IProps> = (props) => {
  const { chatId, botId } = useParams()
  const [expanded, setExpanded] = useState(false)
  const { data: activeBot, isLoading: isActiveBotLoading } = useGetActiveBotQuery({})
  const { data: chat, isLoading: isChatLoading } = useGetChatQuery(
    { queryParams: { chat_id: chatId!, bot_id: botId! } },
    { skip: !chatId },
  )
  const canEdit = !!(chat?.can_invite_users && chat?.bot_id === activeBot?.id)
  const isLoading = isActiveBotLoading || isChatLoading

  useTitle(`PR ADV - Чат`)

  return (
    <Container maxWidth={false}>
      <Box mb={3}>
        <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <GoBack />
          <Button onClick={() => setExpanded(!expanded)} disabled={!canEdit}>
            {expanded ? "Отмена" : "Создать ссылку"}
          </Button>
        </Box>
        <Collapse in={expanded}>
          <Typography variant="h5">Создание новой ссылки</Typography>
          <CreateInviteLinkForm />
        </Collapse>
        {!canEdit && !isLoading && (
          <Alert severity="warning" sx={{ my: 1 }}>
            <Typography>
              Текущий бот не может управлять этим чатом и ссылками
            </Typography>
          </Alert>
        )}
      </Box>
      <Box mt={5}>
        <Typography variant="h5">Ссылки</Typography>
        <InviteLinksTable canEdit={canEdit} />
      </Box>
    </Container>
  )
}
