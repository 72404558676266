import { FC } from "react"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useCreateCampaignMutation } from "../../store"
import { Grid, Stack } from "@mui/material"
import { Name } from "../formFields/name"

interface IProps {}
interface IFormData extends ADV.CreateCampaign {}

export const CreateCampaignForm: FC<IProps> = (props) => {
  return (
    <RtkHookForm<IFormData, ADV.CreateCampaignRes, typeof useCreateCampaignMutation>
      successMessage="Кампания создана успешно"
      mutationHook={useCreateCampaignMutation}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Name<IFormData> required />
          </Stack>
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
