import {
  FieldView,
  InlineCode,
  OutlinedBox,
  PreviousLocation,
  RouterLink,
} from "@/core/components"
import { ADV } from "../interfaces"
import { useGetOrderQuery } from "../store"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { UpdateOrderStatusForm } from "./forms/updateOrderStatusForm"
import { UpdateOrderExtensionsForm } from "./forms/updateOrderExtensionsForm"
import { parseISO } from "date-fns"
import { formatDate } from "@/core/utils"
import { formatTGLink } from "./utils/string"
import { ExpandMore } from "@mui/icons-material"
import { UpdateOrderBotsForm } from "./forms/updateOrderBotsForm"
import Close from "@mui/icons-material/Close"
import { useLocation } from "@/core/hooks"

interface IProps {
  id: ADV.Order["id"]
}

enum TabId {
  Status = 1,
  Extend,
  Links,
  Urls,
}

const LinkPreview = ({
  i,
  link: l,
}: {
  i: number
  link: ADV.Order["links"][number]
}) => (
  <Box key={i}>
    <FieldView
      label="Ссылка"
      value={
        <InlineCode text={`${formatTGLink(l.link)}?start=${l.referral_code}`} />
      }
    />
    <FieldView label="Бот" value={l.link} />
    <FieldView label="Реф. код" value={l.referral_code} />
    <FieldView label="Тип" value={l.type} />
  </Box>
)

export const Order = ({ id }: IProps) => {
  const { state } = useLocation<PreviousLocation>()
  const [expanded, setExpanded] = useState<TabId | undefined>()
  const { data, isLoading } = useGetOrderQuery({ pathParams: { id } })

  useEffect(() => {
    setExpanded(undefined)
  }, [data])

  const activeBots = data?.links?.filter((l) => !l.ban)
  const bannedBots = data?.links?.filter((l) => l.ban)

  if (isLoading) {
    return <LinearProgress />
  }
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="h5" paragraph>
          Заказ ID:{data?.id}
        </Typography>
        <Box>
          <ButtonGroup>
            <Button onClick={() => setExpanded(TabId.Extend)}>Продлить</Button>
            <Button onClick={() => setExpanded(TabId.Links)}>
              Добавить бота
            </Button>
            <Button onClick={() => setExpanded(TabId.Urls)}>
              Добавить ссылку
            </Button>
            <Button onClick={() => setExpanded(TabId.Status)}>
              Обновить статус
            </Button>
          </ButtonGroup>
          {!!expanded && (
            <Box ml={1} display="inline">
              <Button onClick={() => setExpanded(undefined)}>
                <Close />
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Collapse in={!!expanded}>
        <Box mb={6}>
          {expanded === TabId.Extend && (
            <>
              <Typography variant="h5">Продление заказа</Typography>
              <UpdateOrderExtensionsForm id={id} />
            </>
          )}
          {expanded === TabId.Status && (
            <>
              <Typography variant="h5">Обновление статуса</Typography>
              <UpdateOrderStatusForm id={id} />
            </>
          )}
          {expanded === TabId.Links && (
            <>
              <Typography variant="h5">Добавление ботов</Typography>
              <UpdateOrderBotsForm id={id} form="bot" />
            </>
          )}
          {expanded === TabId.Urls && (
            <>
              <Typography variant="h5">Добавление ссылок</Typography>
              <UpdateOrderBotsForm id={id} form="url" />
            </>
          )}
        </Box>
      </Collapse>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FieldView
            label={"Контрагент"}
            value={
              <RouterLink<PreviousLocation>
                color="secondary"
                title={`${data?.agent.name}`}
                to={`../agents/${data?.agent.id}`}
                state={{
                  from: window.location.pathname,
                  title: `К странице заказа ID:${data?.id}`,
                }}
              />
            }
          />
          <FieldView
            label={"Ссылка"}
            value={
              data?.agent.url && (
                <Link
                  color="secondary"
                  href={formatTGLink(data?.agent.url)}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  {data?.agent.url}
                </Link>
              )
            }
          />
          <FieldView label={"Кампания"} value={data?.campaign_name} />
          <FieldView
            label={"Дата начала"}
            value={data?.date_start && formatDate(parseISO(data.date_start))}
          />
          <FieldView
            label={"Дата окончания"}
            value={data?.date_finish && formatDate(parseISO(data.date_finish))}
          />
          <FieldView label={"Тип"} value={data?.type_name} />
          <FieldView label={"Формат"} value={data?.format_name} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Stack spacing={2}>
            <FieldView label={"Статус"} value={data?.status_name} />
            <FieldView
              label={"Оплаты"}
              value={
                <Stack spacing={1}>
                  {data?.payments?.map((p, i) => (
                    <Box key={i}>
                      <FieldView
                        label="Дата оплаты"
                        value={formatDate(parseISO(p.date_payment))}
                      />
                      <FieldView label="Сумма" value={p.sum} />
                      <FieldView label="Комменттарий" value={p.comment} />
                    </Box>
                  ))}
                </Stack>
              }
            />
            <FieldView
              label={"Продления"}
              value={
                <Stack spacing={1}>
                  {data?.extensions?.map((e, i) => (
                    <Box key={i}>
                      <FieldView
                        label="Дата начала"
                        value={formatDate(parseISO(e.date_start))}
                      />
                      <FieldView
                        label="Дата окончания"
                        value={
                          e.date_finish && formatDate(parseISO(e.date_finish))
                        }
                      />
                    </Box>
                  ))}
                </Stack>
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography paragraph variant="body1">
            Боты:
          </Typography>
          <Stack spacing={1}>
            {activeBots?.map((l, i) => (
              <OutlinedBox key={i}>
                <LinkPreview i={i} link={l} />
              </OutlinedBox>
            ))}
            {!!bannedBots?.length && (
              <Accordion
                disableGutters
                component={Box}
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  "::before": { content: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ p: 0 }}
                >
                  Заблокированные боты:
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Stack spacing={1}>
                    {bannedBots?.map((l, i) => (
                      <OutlinedBox key={i}>
                        <LinkPreview i={i} link={l} />
                      </OutlinedBox>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
