import { Stack, Button, Box } from "@mui/material"
import { FieldValues, TextFieldElement, useFormContext } from "react-hook-form-mui"
import { EMPTY_CONTACT } from "@/modules/adv2/constants/adv"
import { ADV } from "../../interfaces"

interface IProps<T extends FieldValues> {
  minLength?: number
}
interface IField {
  contacts: ADV.CreateAgent["contacts"]
}

export function AgentContacts<T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element {
  const ctx = useFormContext<ADV.CreateAgent>()
  const contacts = ctx?.watch("contacts")

  const deleteContact = (index: number) => {
    const newValue = contacts?.filter((v, i) => i !== index)
    ctx.setValue(
      "contacts",
      Array.isArray(newValue) && newValue.length > 0 ? newValue : [],
    )
  }

  const minArrLength =
    props.minLength !== undefined && props.minLength > -1 ? props.minLength : 1

  return (
    <Stack spacing={4}>
      {contacts?.map((_, i) => (
        <Stack spacing={2} key={i}>
          <TextFieldElement<ADV.CreateAgent>
            name={`contacts.${i}.link`}
            label="Контакт"
            required
          />
          <TextFieldElement<ADV.CreateAgent>
            name={`contacts.${i}.communication_method`}
            label="Метод коммуникации"
          />
          {contacts?.length > minArrLength && (
            <Box display="flex" flexDirection="row-reverse">
              <Button variant="text" onClick={() => deleteContact(i)}>
                Удалить
              </Button>
            </Box>
          )}
        </Stack>
      ))}
      <Button
        onClick={() => ctx.setValue("contacts", [...(contacts ?? []), EMPTY_CONTACT])}
      >
        Добавить контакт
      </Button>
    </Stack>
  )
}
