import { ADV } from "../../interfaces"
import { AutocompleteElement, FieldValues } from "react-hook-form-mui"
import { useFormatsListQuery } from "../../store"

interface IProps<T extends FieldValues> {
  required: boolean
}
type IField = Pick<ADV.CreateOrder, "format_id">

export const Format = <T extends FieldValues = IField>(props: IProps<T>) => {
  const { required } = props
  const { data, isFetching: isLoading } = useFormatsListQuery({})

  return (
    <AutocompleteElement<IField>
      name={"format_id"}
      label={"Формат"}
      loading={isLoading}
      matchId
      required={required}
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.Format) => option?.name,
      }}
      options={data ?? []}
    />
  )
}
