import React from "react"
import { DatePickerElement, TextFieldElement } from "react-hook-form-mui"
import { IOrderFormData } from "../forms/createOrderForm"

interface IProps {
  required?: boolean
  disableSum?: boolean
}
interface IField {
  payment: IOrderFormData["payment"]
  // status_id?: number
}

export const Payment = <T extends IField>({ required, disableSum }: IProps) => {
  // const ctx = useFormContext<IField>()
  // const statusId = ctx.watch("status_id")

  return (
    <>
      <DatePickerElement<IField>
        name="payment.date_payment"
        label="Дата платежа"
        required={required}
      />
      <TextFieldElement<IField>
        name="payment.sum"
        label="Сумма в рублях"
        type="number"
        required={required}
        disabled={disableSum}
        inputProps={{
          step: 1000,
          min: 0,
        }}
      />
    </>
  )
}
