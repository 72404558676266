import { ADV } from "../interfaces"

export const BELONG_FOR = {
  agents: {
    name: "Агенты",
  },
  links: {
    name: "Ссылки",
  },
  orders: {
    name: "Заказы",
  },
  bots: {
    name: "Боты",
  },
  payments: {
    name: "Оплаты",
  },
} as const

export const EMPTY_LINK: ADV.CreateLink = {
  type_id: undefined,
  bot_id: undefined,
  ref_code_id: undefined,
}
export const EMPTY_URL: ADV.Url = {
  url: "",
  utm: undefined
}
export const EMPTY_CONTACT: ADV.CreateContact = {
  link: "",
  communication_method: ""
}

export const DEFAULT_PAYMENT_SUM = 10000
export const WAITING_TO_PAYMENT_STATUS_ID = 4
export const HAS_PAYED_STATUS_ID = 3

export const HASH_SEPARATOR = "_"
