import { FC } from "react"
import { DataGrid, PreviousLocation } from "@/core/components"
import { ADV } from "../../interfaces"
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-pro"
import {
  getAdvState,
  setAgentsPaginationModel,
  usePaymentsListQuery,
} from "../../store"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks"
import { Preview } from "@mui/icons-material"
import { useNavigate } from "@/core/hooks"
import { v4 } from "uuid"
import { formatDate } from "@/core/utils"

interface IProps {}
export const PaymentsTable: FC<IProps> = (props) => {
  const navigate = useNavigate<PreviousLocation>()
  const dispatch = useAppDispatch()
  const {
    payments: { page, pageSize },
    paymentsFilters,
  } = useAppSelector(getAdvState)
  const { isFetching, data } = usePaymentsListQuery({
    queryParams: {
      page: page + 1,
      limit: pageSize,
      ...paymentsFilters,
    },
  })

  const columns: GridColDef<ADV.PaymentsListRes["data"][number]>[] = [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Контрагент",
      field: "agent",
      valueGetter: ({ value }) => value.name,
      flex: 3,
    },
    {
      headerName: "Кампания",
      field: "order",
      valueGetter: ({ value }) => value.campaign_name,
      flex: 3,
    },
    {
      headerName: "Сумма",
      field: "payment.sum",
      valueGetter: ({ row }) => row.payment.sum,
      flex: 2,
    },
    {
      headerName: "Комментарий",
      field: "payment.comment",
      valueGetter: ({ row }) => row.payment.comment,
      flex: 5,
    },
    {
      headerName: "Дата",
      field: "payment.date_payment",
      valueGetter: ({ row }) => row.payment.date_payment,
      valueFormatter: ({ value }) => formatDate(value),
      flex: 3,
    },
  ]

  return (
    <DataGrid<ADV.PaymentsListRes["data"][number]>
      columns={columns}
      rows={data?.data ?? []}
      getRowId={(row) => row.id}
      rowCount={data?.count ?? 0}
      loading={isFetching}
      // unstable_headerFilters
      filterMode="client"
      sortingMode="client"
      pagination
      paginationMode="server"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      onPaginationModelChange={(model) =>
        dispatch(setAgentsPaginationModel(model))
      }
      initialState={{
        pagination: { paginationModel: { page, pageSize } },
      }}
      rowActions={[
        (r) => (
          <GridActionsCellItem
            label="Открыть"
            icon={<Preview />}
            onClick={() =>
              navigate(`../orders/${r.row.order.id.toString()}`, {
                state: {
                  from: window.location.pathname,
                  title: "К списку платежей",
                },
              })
            }
          />
        ),
      ]}
    />
  )
}
